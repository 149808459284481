<div class="content mat-elevation-z8"
     *ngIf="(filteredUsers$ | async) as users">

  <!--  -->
  <mat-expansion-panel #searchPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="filter-panel-title">
        Filters
        <button mat-button
                color="primary"
                (click)="loadData($event)">Search</button>
        <button mat-button
                color="primary"
                (click)="resetFilters($event)">Reset</button>

      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>

      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput
               type="text"
               [(ngModel)]="filters.userName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput
               type="text"
               [(ngModel)]="filters.firstName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput
               type="text"
               [(ngModel)]="filters.lastName">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select [(ngModel)]="filters.role">
          <mat-option value="">- Any -</mat-option>
          <mat-option *ngFor="let role of roles"
                      [value]="role">
            {{role}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Communication Groups</mat-label>
        <mat-select [(ngModel)]="filters.communicationGroup">
          <mat-option value="">- Any -</mat-option>
          @for(locationGroup of notificationSettings$ | async; track locationGroup.key){
          <mat-optgroup [label]="locationGroup.key">
            @for(location of locationGroup.value; track location.id){
            <mat-option [value]="location.name">{{location.name}}</mat-option>
            }
          </mat-optgroup>
          }
        </mat-select>
      </mat-form-field>

    </ng-template>
  </mat-expansion-panel>


  <mat-table [dataSource]="users">
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        <button mat-icon-button
                tabindex="-1"
                matTooltip="Edit"
                (click)="navigate(row.id)">
          <span class="fa fa-edit"></span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.userName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.firstName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.lastName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.email}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="roles">
      <mat-header-cell *matHeaderCellDef>Roles</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        @for(role of row.roles; track role){
        &bull; {{role}}<br />
        }

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef>Primary Location</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.location}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="timeZone">
      <mat-header-cell *matHeaderCellDef>Time Zone</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.timeZone}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns; sticky: true"
                    class="table-header-primary">
    </mat-header-row>

    <mat-row *matRowDef="let row; let i = index; columns:  columns;">
    </mat-row>
    <div class="no-data-row"
         *matNoDataRow>
      No users found
    </div>
  </mat-table>
</div>

<div class="dp-top-fab-container">

  <button mat-mini-fab
          (click)="addNew()"
          color="accent">
    <span class="fa fa-plus"></span>
  </button>
</div>
