<div class="content mat-elevation-z8">
  <!--  -->
  <mat-expansion-panel #searchPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="filter-panel-title">
        Filters
        <button mat-button
                color="primary"
                (click)="loadData($event)">Search</button>
        <button mat-button
                color="primary"
                (click)="resetFilters($event)">Reset</button>

      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent
                 *ngIf="(cachedFilters$ | async) as cache">

      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput
               [ngModel]="cache.startDate"
               (ngModelChange)="cache.startDate = $event"
               [matDatepicker]="startDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix
                               [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput
               [(ngModel)]="cache.endDate"
               [matDatepicker]="endDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix
                               [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>User Id</mat-label>
        <input matInput
               [(ngModel)]="cache.userId" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Group Id</mat-label>
        <input matInput
               [(ngModel)]="cache.groupId" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Format</mat-label>
        <mat-select [(ngModel)]="cache.messageFormat">
          <mat-option value="">- Any -</mat-option>
          <mat-option value="String">
            String
          </mat-option>
          <mat-option value="O365Email">
            O365Email
          </mat-option>
          <mat-option value="DealerReturn">
            DealerReturn
          </mat-option>
          <mat-option value="HTML">
            HTML
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Was Dismissed</mat-label>
        <mat-select [(ngModel)]="cache.wasDismissed">
          <mat-option value="">- Any -</mat-option>
          <mat-option [value]="true">
            Yes
          </mat-option>
          <mat-option [value]="false">
            No
          </mat-option>
        </mat-select>
      </mat-form-field>

    </ng-template>
  </mat-expansion-panel>
  <mat-table [dataSource]="dataSource"
             matSort
             [matSortActive]="(cachedFilters$ | async).orderBy?.split(' ')[0]"
             [matSortDirection]="(cachedFilters$ | async).orderBy?.split(' ')[1]"
             (matSortChange)="sortFilters()">

    <ng-container matColumnDef="content">
      <mat-header-cell mat-header-cell
                       style="min-width: 450px;"
                       *matHeaderCellDef>Content</mat-header-cell>
      <mat-cell mat-cell
                style="min-width: 450px;"
                *matCellDef="let row">
        @switch (row.messageFormat) {
        @case (messageFormat.O365Email) {
        <depot-notification-email [notification]="row" />
        }
        @case(messageFormat.DealerReturn){

        <depot-notification-dealer-return [notification]="row" />
        }
        @case (messageFormat.HTML) {
        <div [innerHtml]="row.messageData | htmldecode | safe:'html'">

        </div>
        }@default {
        {{row.messageData}}
        }
        }

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sendTo">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Send To</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        @if(row.userId){
        User:{{row.userId}}
        }
        @if(row.groupId){
        Group:{{row.groupId}}

        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="messageFormat">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Format</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        {{row.messageFormat}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dismissed">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Dismissed</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        @if(row.dismissedDate !== null){
        {{row.dismissedDate | utcdate:'short'}} ({{row.dismissedBy}})
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Create Date</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.createdDate | utcdate:'short'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns; sticky: true"
                    class="table-header-primary"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns;"></mat-row>

    <div class="no-data-row"
         *matNoDataRow>
      No notifications found
    </div>
  </mat-table>

  <mat-paginator [disabled]="dataSource?.isLoading$ | async"
                 (page)="loadData()"
                 [pageSize]="(cachedFilters$ | async)?.takeCount"
                 [pageSizeOptions]="[25, 50, 250, 500]">
  </mat-paginator>
</div>
