import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { CancelRequestService, HelperService, SettingsService } from '@depot/@common';
import { INotificationLogSearch, NotificationMessageFormat, NotificationRepositoryService } from '@depot/@data';
import { NotificationLogGridDataSource } from '@depot/admin/notification-grid/notification-log-grid.datasource';

import { BehaviorSubject } from 'rxjs';

import { isMatch } from 'underscore';

@Component({
  selector: 'depot-notification-grid',
  templateUrl: './notification-grid.component.html',
  styleUrls: ['./notification-grid.component.scss']
})
export class NotificationGridComponent implements OnInit, OnDestroy {
  public dataSource = new NotificationLogGridDataSource(this.notificationService);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel) searchPanel: MatExpansionPanel;
  public messageFormat = NotificationMessageFormat;
  public cachedFilters$ = new BehaviorSubject<INotificationLogSearch>({});
  public hasFilters$ = new BehaviorSubject<boolean>(false);
  get defaultFilters(): INotificationLogSearch {
    return {
      startDate: null,
      endDate: null,
      userId: null,
      groupId: null,
    };
  }
  public columns = ['content', 'sendTo', 'messageFormat', 'dismissed', 'createdDate'];
  private get filterName() { return 'notification_log_grid'; }

  constructor(
    private helper: HelperService,
    private notificationService: NotificationRepositoryService,
    private settingsService: SettingsService,
    private httpCancelService: CancelRequestService,
    private destroyRef: DestroyRef,
  ) {
    this.dataSource.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => this.helper.IsGlobalSpinner$.set(x));

  }

  ngOnInit() {
    this.dataSource.totalRows$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: number) => this.paginator.length = rows);

    this.settingsService.dashboardSettings<INotificationLogSearch>(this.filterName).then(cachedData => {

      if (!cachedData) {
        cachedData = this.defaultFilters;
      }
      if (!isMatch(cachedData, this.defaultFilters)) {
        this.searchPanel.open();
      }
      cachedData.pageIndex = this.paginator.pageIndex;
      cachedData.takeCount = cachedData.takeCount > 0 ? cachedData.takeCount : this.paginator.pageSizeOptions[0];
      this.paginator.pageSize = cachedData.takeCount;
      this.paginator.pageIndex = cachedData.pageIndex;
      this.cachedFilters$.next(cachedData);
      this.loadData(null);
    });

  }

  public async loadData(e?: Event | null) {
    this.httpCancelService.cancelPendingRequests();
    if (e) {
      e.stopPropagation();
    }
    let sortInput = '';
    if (this.sort.active && this.sort.direction) {
      sortInput = this.sort.active + ' ' + this.sort.direction;
    }

    const values = this.cachedFilters$.getValue();
    values.takeCount = this.paginator.pageSize;
    values.pageIndex = this.paginator.pageIndex;
    values.orderBy = sortInput;

    const cachedData = await this.settingsService.dashboardSettings<INotificationLogSearch>(this.filterName, values);

    this.dataSource.loadData(cachedData);
    this.helper.IsGlobalBar$.set(false);

  }


  public resetFilters(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    this.cachedFilters$.next(this.defaultFilters);
    this.paginator.firstPage();
    this.loadData();
  }

  public sortFilters() {
    this.paginator.firstPage();
    this.loadData();

  }



  ngOnDestroy(): void {

    this.cachedFilters$.complete();
    // this.depotContext.close();
  }


}
