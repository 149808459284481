import { Component, OnInit } from '@angular/core';

import { AdminRepositoryService } from '@depot/@data';

import { Subject } from 'rxjs';

@Component({
  selector: 'depot-server-settings',
  templateUrl: './server-settings.component.html',
  styleUrls: ['./server-settings.component.scss']
})
export class ServerSettingsComponent implements OnInit {

  public data$ = new Subject<any>();
  constructor(public adminService: AdminRepositoryService) { }

  ngOnInit() {
    this.adminService.getSettings().subscribe(x => {
      this.data$.next(x);

    });
  }

}
