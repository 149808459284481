<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>Settings</mat-panel-title>
  </mat-expansion-panel-header>


  <div class="tw-grid xs:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2">

    <div class="tw-px-4 tw-py-2 tw-text-center dp-grid-tile tw-break-words"
         style="border: 1px solid var(--dp-row-border)"
         *ngFor="let item of (data$ | async)">
      <span style="font-weight: 900;">{{item.key}}</span>
      <div style="flex-grow: 1;"
           class=""></div>
      {{item.value}}
    </div>
  </div>
</mat-expansion-panel>
